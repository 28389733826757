import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChartComponent from './ChartComponent';
import './style.css';

const Form = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [file, setFile] = useState(null);
    const [extension, setExtension] = useState('csv');
    const [loading, setLoading] = useState(false);
    const [showChart, setShowChart] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState('');
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('pl'); // Ustaw domyślną wartość na 'pl'

    useEffect(() => {
        fetch('/google-countries.json')
            .then(response => response.json())
            .then(data => {
                console.log('Fetched data:', data); // Sprawdź dane
                setCountries(data);
            })
            .catch(error => console.error('Error fetching countries:', error))
            .finally(() => setLoading(false));
    }, []);

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value); // Aktualizuj stan wybranego kraju
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setShowChart(false);
        setError(''); // Resetuj błąd

        const formData = new FormData();
        formData.append('search_term', searchTerm);
        formData.append('file', file);
        formData.append('country', selectedCountry);
        formData.append('extension', extension);

        try {
            const response = await axios.post('/api/search', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                responseType: 'blob',
            });

            const histogramData = JSON.parse(response.headers['histogram-data']);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1]);
            document.body.appendChild(link);
            link.click();

            setChartData({
                labels: Object.keys(histogramData),
                values: Object.values(histogramData),
            });
            setShowChart(true);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setError('Liczba słów kluczowych nie może przekraczać 20.');
            } else {
                console.error('There was an error!', error);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <h1>Narzędzie do Monitorowania Pozycji w Google</h1>
            <h2>Sprawdź, jak Twoja strona internetowa radzi sobie w wynikach wyszukiwania</h2>
            <form onSubmit={handleSubmit} id="search-form">
                <label htmlFor="search_term">Adres strony:</label>
                <input
                    type="text"
                    id="search_term"
                    name="search_term"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    required
                />
                <br />
                <label htmlFor="file">Wczytaj plik TXT lub CSV ze słowami kluczowymi:</label>
                <input
                    type="file"
                    id="file"
                    name="file"
                    accept=".csv,.txt"
                    onChange={(e) => setFile(e.target.files[0])}
                    required
                />
                <br />
                <label htmlFor="country">Kraj:</label>
                <select id="country" name="country" value={selectedCountry} onChange={handleCountryChange}>
                    {countries.length > 0 ? (
                        countries.map(country => (
                            <option key={country.country_code} value={country.country_code}>
                                {country.country_name}
                            </option>
                        ))
                    ) : (
                        <option>No countries available</option>
                    )}
                </select>
                <br />
                <label htmlFor="extension">Format pliku wygenerowanego raportu:</label>
                <select
                    id="extension"
                    name="extension"
                    value={extension}
                    onChange={(e) => setExtension(e.target.value)}
                >
                    <option value="csv">CSV</option>
                    <option value="xlsx">XLSX</option>
                </select>
                <br />
                <button type="submit" className={`loading-button ${loading ? 'loading' : ''}`} id="submit-button">
                    <span className="button-text">Szukaj</span>
                    <span className="spinner"></span>
                </button>
            </form>

            {/* Wyświetlanie komunikatu o błędzie */}
            {error && <p className="error-message">{error}</p>}

            {/* Wyświetlanie wykresu z danych histogramu */}
            {showChart && <ChartComponent data={chartData} />}
        </div>
    );
};

export default Form;
